import React from 'react';
import { Typography, Box, Link } from '@mui/material';
import BoxWrapper from '../../components/common/BoxWrapper';

const PrivacyPage: React.FC = () => {
  return (
    <BoxWrapper>
      <Typography variant="h5" gutterBottom>
        Data Protection Policy
      </Typography>
      <Typography variant="body1">
        1. Introduction
      </Typography>
      <Typography variant="body2" paragraph>
        Reuros Exchange is committed to protecting your personal data and respecting your privacy. This Data Protection Policy explains how we collect, use, store, and protect your personal information in compliance with the European Union's General Data Protection Regulation (GDPR).
      </Typography>
      <Typography variant="body1">
        2. Data Controller
      </Typography>
      <Typography variant="body2" paragraph>
        Reuros Exchange, is the Data Controller for the personal data we collect and process.
      </Typography>
      <Typography variant="body1">
        3. Data We Collect
      </Typography>
      <Typography variant="body2" paragraph>
        We collect the following categories of personal data:
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li><strong>Identity Data:</strong> Name, username, and other identification details.</li>
        <li><strong>Contact Data:</strong> Email address, phone number, and postal address.</li>
        <li><strong>Transaction Data:</strong> Information related to currency exchange transactions, including transaction amount, currency type, and transaction history.</li>
        <li><strong>Verification Data:</strong> Documentation or information required for identity verification (e.g., passport, proof of address).</li>
        <li><strong>Technical Data:</strong> IP address, browser type, and other technical details related to your use of our platform.</li>
      </Box>
      <Typography variant="body1">
        4. How We Use Your Data
      </Typography>
      <Typography variant="body2" paragraph>
        We use your personal data for the following purposes:
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li><strong>Providing Services:</strong> To facilitate currency exchange transactions and related services.</li>
        <li><strong>User Verification:</strong> To ensure the security and integrity of the platform by verifying the identity of users.</li>
        <li><strong>Communication:</strong> To send you transactional updates, account notifications, and marketing communications (with your consent).</li>
        <li><strong>Security and Fraud Prevention:</strong> To monitor and prevent fraudulent activity.</li>
        <li><strong>Legal Obligations:</strong> To comply with legal requirements, including anti-money laundering regulations.</li>
      </Box>
      <Typography variant="body1">
        5. Legal Basis for Processing Your Data
      </Typography>
      <Typography variant="body2" paragraph>
        We process your personal data based on the following legal grounds:
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li><strong>Consent:</strong> Where you have provided explicit consent (e.g., for marketing communications).</li>
        <li><strong>Contractual Necessity:</strong> To fulfill the terms of our agreement with you (e.g., facilitating transactions).</li>
        <li><strong>Legal Obligation:</strong> Where required by law (e.g., identity verification for compliance).</li>
        <li><strong>Legitimate Interests:</strong> For our legitimate business interests, such as fraud prevention and improving our services.</li>
      </Box>
      <Typography variant="body1">
        6. Data Retention
      </Typography>
      <Typography variant="body2" paragraph>
        We retain your personal data for as long as necessary to fulfill the purposes outlined in this policy or as required by law. After this period, we will securely delete or anonymize your data.
      </Typography>
      <Typography variant="body1">
        7. Data Sharing
      </Typography>
      <Typography variant="body2" paragraph>
        We do not share your personal data with third parties except in the following situations:
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li><strong>Service Providers:</strong> We may share your data with trusted service providers who assist us in operating the platform (e.g., payment processors, email services).</li>
        <li><strong>Legal Compliance:</strong> We may disclose your data to authorities if required by law, such as for anti-money laundering purposes or to respond to a legal request.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale, your personal data may be transferred as part of the business assets.</li>
      </Box>
      <Typography variant="body1">
        8. International Data Transfers
      </Typography>
      <Typography variant="body2" paragraph>
        As a platform operating globally, your personal data may be transferred to, and processed in, countries outside the European Economic Area (EEA). We ensure that adequate safeguards are in place to protect your data in accordance with GDPR, such as using Standard Contractual Clauses or ensuring the recipient is part of the EU-U.S. Privacy Shield Framework.
      </Typography>
      <Typography variant="body1">
        9. Your Rights
      </Typography>
      <Typography variant="body2" paragraph>
        Under the GDPR, you have the following rights regarding your personal data:
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li><strong>Right to Access:</strong> You can request access to the personal data we hold about you.</li>
        <li><strong>Right to Rectification:</strong> You can request corrections to any inaccurate or incomplete data.</li>
        <li><strong>Right to Erasure (Right to be Forgotten):</strong> You can request the deletion of your personal data under certain conditions.</li>
        <li><strong>Right to Restriction of Processing:</strong> You can request that we limit the processing of your data in specific situations.</li>
        <li><strong>Right to Data Portability:</strong> You can request your data in a structured, commonly used, and machine-readable format.</li>
        <li><strong>Right to Object:</strong> You can object to the processing of your data based on legitimate interests or for direct marketing purposes.</li>
        <li><strong>Right to Withdraw Consent:</strong> You can withdraw your consent at any time where we rely on consent for processing.</li>
      </Box>
      <Typography variant="body2" paragraph>
        To exercise these rights, please contact us at <Link href="mailto:privacy@reurosexchange.com">privacy@reurosexchange.com</Link>.
      </Typography>
      <Typography variant="body1">
        10. Security Measures
      </Typography>
      <Typography variant="body2" paragraph>
        We take the security of your personal data seriously and implement appropriate technical and organizational measures to protect your information from unauthorized access, loss, or alteration. These measures include encryption, access controls, and regular security audits.
      </Typography>
      <Typography variant="body1">
        11. Cookies and Tracking Technologies
      </Typography>
      <Typography variant="body2" paragraph>
        Our platform uses cookies and similar tracking technologies to enhance user experience, analyze usage patterns, and personalize content. You can manage your cookie preferences through your browser settings. For more details, please refer to our Cookie Policy.
      </Typography>
      <Typography variant="body1">
        12. Changes to this Data Protection Policy
      </Typography>
      <Typography variant="body2" paragraph>
        We may update this Data Protection Policy from time to time to reflect changes in our practices, legal requirements, or business operations. Any updates will be communicated via the platform or through other appropriate channels.
      </Typography>
      <Typography variant="body1">
        13. Contact Us
      </Typography>
      <Typography variant="body2" paragraph>
        If you have any questions or concerns about how we handle your personal data, or if you wish to exercise your rights, please contact us at:
      </Typography>
      <Typography variant="body2" paragraph>
        Email: <Link href="mailto:privacy@reurosexchange.com">privacy@reurosexchange.com</Link>
      </Typography>
      <Typography variant="body2" paragraph>
        Last updated: December 2024
      </Typography>
    </BoxWrapper>
  );
}

export default PrivacyPage;