import React, { useEffect, useRef } from 'react';
import { Snackbar, Slide, Alert } from '@mui/material';
import { useLocation, Location } from 'react-router-dom';

interface SnackbarMessageProps {
  open: boolean;
  message: string;
  severity: 'error' | 'warning' | 'info' | 'success';
  onClose: () => void;
}

function SlideTransition(props: any) {
  return <Slide {...props} direction="down" />;
}

const SnackbarMessage: React.FC<SnackbarMessageProps> = ({ open, message, severity, onClose }) => {
  const location = useLocation();
  const prevLocation = useRef<Location>(location);

  useEffect(() => {
    if (open) {
      prevLocation.current = location;
    }
  }, [location, open]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={SlideTransition}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;