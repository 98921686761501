import React, { useState, useEffect, FormEvent } from 'react';
import { Typography, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import BoxWrapper from '../../components/common/BoxWrapper';
import UserService from '../../apis/services/UserService';
import AlertMessage from '../../components/common/AlertMessage';

function ResendPage() {
  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');
  const [resendSuccess, setSuccess] = useState<string[]>([]);
  const [resendError, setError] = useState<string[]>([]);
  const [isCooldown, setCooldown] = useState<boolean>(false);
  const [cooldownTimeLeft, setCooldownTimeLeft] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const lastSent = localStorage.getItem('lastResendTime');
    if (lastSent) {
      const cooldownTime = 3 * 60 * 1000;
      const timePassed = Date.now() - new Date(lastSent).getTime();
      if (timePassed < cooldownTime) {
        setCooldown(true);
        const remainingTime = cooldownTime - timePassed;
        setCooldownTimeLeft(Math.ceil(remainingTime / 1000));
        const interval = setInterval(() => {
          setCooldownTimeLeft(prev => {
            if (prev <= 1) {
              clearInterval(interval);
              setCooldown(false);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
        return () => clearInterval(interval); 
      }
    }
  }, []);

  const handleCloseError = (index: number) => {
    setError(prev => prev.filter((_, i) => i !== index));
  };

  const handleCloseSuccess = (index: number) => {
    setSuccess(prev => prev.filter((_, i) => i !== index));
  };

  const handleResendEmail = async (e: FormEvent) => {
    e.preventDefault();
    if (!isCooldown && !isSubmitting && email) {
      setIsSubmitting(true);
      try {
        const response = await UserService.resendEmail(email);
        if (response.data?.success) {
          setSuccess([response.data.data || 'Confirmation e-mail resent successfully!']);
          setError([]);
          localStorage.setItem('lastResendTime', new Date().toISOString());
          setCooldown(true);
          setCooldownTimeLeft(3 * 60); 
          const interval = setInterval(() => {
            setCooldownTimeLeft(prev => {
              if (prev <= 1) {
                clearInterval(interval);
                setCooldown(false);
                return 0;
              }
              return prev - 1;
            });
          }, 1000);
          return () => clearInterval(interval); 
        } else {
          setError(response.data.errors || ['Failed to resend confirmation e-mail.']);
          setSuccess([]);
        }
      } catch (error: any) {     
        setError(error.response?.data?.errors || ['An error occurred while resending the confirmation email.']);
        setSuccess([]);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <BoxWrapper>
      <AlertMessage messages={resendError} severity="error" onClose={handleCloseError} />
      <AlertMessage messages={resendSuccess} severity="success" onClose={handleCloseSuccess} />
      <Typography variant="h5" gutterBottom>
        E-mail Confirmation Required
      </Typography>
      <Typography variant="body1">
        Thank you for registering! Please check your e-mail and follow the instructions to confirm your account.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleResendEmail}
        sx={{ mt: 2 }}
        disabled={isCooldown || isSubmitting}
      >
        Resend Confirmation E-mail
      </Button>
      {isCooldown && (
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          Please wait {cooldownTimeLeft} seconds before resending the confirmation e-mail.
        </Typography>
      )}
    </BoxWrapper>
  );
}

export default ResendPage;