import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import BoxWrapper from '../../components/common/BoxWrapper';
import userService from '../../apis/services/UserService';
import AlertMessage from '../../components/common/AlertMessage';

const ConfirmPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const token = searchParams.get('token');
  const [confirmationMessage, setConfirmationMessage] = useState<string>('');
  const [confirmationError, setConfirmationError] = useState<string[]>([]);
  const isRequestSentRef = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id && token && !isRequestSentRef.current) {
      const confirmEmail = async () => {
        isRequestSentRef.current = true;
        try {
          const response = await userService.confirmEmail({ id, token });
          setConfirmationMessage(response.data.data || 'E-mail confirmed successfully!');
          setConfirmationError([]);
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        } catch (error: any) {
          setConfirmationError(error.response?.data?.errors || ['Error confirming e-mail.']);
          setConfirmationMessage('');
        }
      };

      confirmEmail();
    } else if (!id || !token) {
      navigate('/resend-confirmation');
    }
  }, [id, token, navigate]);

  const handleCloseError = (index: number) => {
    setConfirmationError(prev => prev.filter((_, i) => i !== index));
  };

  const handleCloseSuccess = () => {
    setConfirmationMessage('');
  };

  return (
    <BoxWrapper>
      <Typography variant="h5" gutterBottom>
        Processing email confirmation...
      </Typography>
      {confirmationMessage && <AlertMessage messages={[confirmationMessage]} severity="success" onClose={handleCloseSuccess} />}
      {confirmationError.length > 0 && <AlertMessage messages={confirmationError} severity="error" onClose={handleCloseError} />}
    </BoxWrapper>
  );
}

export default ConfirmPage;