import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp';
import LoginSharpIcon from '@mui/icons-material/LoginSharp';
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../hooks/AuthProvider';

interface BottomNavProps {
  isLoggedIn: boolean;
  handleLogout: () => void;
}

const BottomNav: React.FC<BottomNavProps> = ({ isLoggedIn, handleLogout }) => {
  const theme = useTheme();
  const [value, setValue] = useState<number>(0);
  const { isAuthenticated } = useAuth();

  return (
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          background: theme.palette.background.paper,
          boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      >
      <BottomNavigationAction
        label="Home"
        icon={<HomeSharpIcon />}
        component={Link}
        to="/"
        showLabel
        sx={{
          '&:hover': { backgroundColor: theme.palette.action.hover },
        }}
      />
      <BottomNavigationAction
        label="About"
        icon={<InfoSharpIcon />}
        component={Link}
        to="/about"
        showLabel
        sx={{
          '&:hover': { backgroundColor: theme.palette.action.hover },
        }}
      />
      {isAuthenticated ? [
        <BottomNavigationAction
          key="profile"
          label="Profile"
          icon={<AccountBoxSharpIcon />}
          component={Link}
          to="/profile"
          showLabel
          sx={{
            '&:hover': { backgroundColor: theme.palette.action.hover },
          }}
        />,
        <BottomNavigationAction
          key="logout"
          label="Logout"
          icon={<LogoutSharpIcon />}
          onClick={handleLogout}
          showLabel
          sx={{
            '&:hover': { backgroundColor: theme.palette.action.hover },
          }}
        />
      ] : [
        <BottomNavigationAction
          key="register"
          label="Register"
          icon={<PersonAddAltSharpIcon />}
          component={Link}
          to="/register"
          showLabel
          sx={{
            '&:hover': { backgroundColor: theme.palette.action.hover },
          }}
        />,
        <BottomNavigationAction
          key="login"
          label="Login"
          icon={<LoginSharpIcon />}
          component={Link}
          to="/login"
          showLabel
          sx={{
            '&:hover': { backgroundColor: theme.palette.action.hover },
          }}
        />
      ]}
    </BottomNavigation>
  );
}

export default BottomNav;