import React, { useEffect, useState } from 'react';
import { Typography, List, ListItem, ListItemText, IconButton, Tooltip, Snackbar, Box, TextField } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BoxWrapper from '../../components/common/BoxWrapper';
import userService from '../../apis/services/UserService';
import moment from '../../utils/Moment';
import { User } from '../../apis/models/User';

const AdminPage: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await userService.getAllUsers();
                if (response.data.success) {
                    setUsers(response.data.data);
                    setFilteredUsers(response.data.data);
                }
            } catch (error) {
                console.error('Failed to fetch users:', error);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const results = users.filter(user =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredUsers(results);
    }, [searchTerm, users]);

    const handleCopy = (id: string) => {
        navigator.clipboard.writeText(id);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <BoxWrapper>
            <Typography variant="h5" gutterBottom>
                Admin Dashboard
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
                Manage application settings and user data here.
            </Typography>
            <TextField
                label="Search Users"
                variant="outlined"
                fullWidth
                sx={{ mb: 3 }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Typography variant="h6" gutterBottom>
                Users
            </Typography>
            <List>
                {filteredUsers.map((user) => (
                    <ListItem key={user.id} secondaryAction={
                        <Box display="flex" alignItems="center">
                            <Typography component="span" variant="body2" color="textSecondary" sx={{ mr: 2 }}>
                                ID: {user.id}
                            </Typography>
                            <Tooltip title="Copy User ID">
                                <IconButton edge="end" onClick={() => handleCopy(user.id)}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }>
                        <ListItemText 
                            primary={`${user.name} ${user.surname}`} 
                            secondary={
                                <>
                                    <Typography component="span" variant="body2" color="textPrimary">
                                        {user.email}
                                    </Typography>
                                    <br />
                                    <Typography component="span" variant="body2" color="textSecondary">
                                        Last Login: {user.lastLogin ? moment(user.lastLogin).format('DD/MM/YYYY HH:mm:ss') : 'N/A'}
                                    </Typography>
                                    <br />
                                    <Typography component="span" variant="body2" color="textSecondary">
                                        Registration Date: {moment(user.registrationDate).format('DD/MM/YYYY HH:mm:ss')}
                                    </Typography>
                                </>
                            } 
                        />
                    </ListItem>
                ))}
            </List>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message="User ID copied to clipboard"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </BoxWrapper>
    );
};

export default AdminPage;
