import React, { createContext, useState, useEffect, useCallback, ReactNode } from 'react';
import userService from '../apis/services/UserService';
import SnackbarMessage from '../components/common/SnackbarMessage';
import { User } from '../apis/models/User';

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  loading: boolean;
  login: () => void;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuthProvider = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [logoutMessage, setLogoutMessage] = useState<string>('');

  const clearLogoutMessage = useCallback(() => {
    setLogoutMessage('');
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setUser(null);
    setIsAuthenticated(false);
    setLogoutMessage('You have successfully logged out.');
    setTimeout(clearLogoutMessage, 3000);
  }, [clearLogoutMessage]);

  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      logout();
      setLoading(false);
      return;
    }

    try {
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      const { uid, name } = tokenPayload;
      const response = await userService.getUser(uid);
      if (response.data.success && response.data.data) {
        setUser({ ...response.data.data, id: uid, name });
        setIsAuthenticated(true);
      } else {
        throw new Error('Invalid user data');
      }
    } catch (error) {
      console.error('Error during login:', error);
      logout();
    } finally {
      setLoading(false);
    }
  }, [logout]);

  useEffect(() => {
    checkAuth();
    if (!localStorage.getItem('token')) {
      clearLogoutMessage();
    }
  }, [checkAuth, clearLogoutMessage]);

  const login = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      logout();
      return;
    }

    try {
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      const { uid, name } = tokenPayload;
      const response = await userService.getUser(uid);

      if (response.data.success && response.data.data) {
        setUser({ ...response.data.data, id: uid, name });
        setIsAuthenticated(true);
      } else {
        throw new Error('Invalid user data');
      }
    } catch (error) {
      console.error('Error during login:', error);
      logout();
    }
  }, [logout]);

  return {
    isAuthenticated,
    user,
    loading,
    login,
    logout,
    logoutMessage,
    clearLogoutMessage,
  };
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = useAuthProvider();

  return (
    <AuthContext.Provider value={auth}>
      {children}
      <SnackbarMessage
        open={!!auth.logoutMessage}
        message={auth.logoutMessage}
        severity="success"
        onClose={auth.clearLogoutMessage}
      />
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};