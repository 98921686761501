import { AxiosResponse } from 'axios';
import BaseApiService from './BaseApiService';
import { User, UserConfirmEmail, UserLogin, UserRegister, UserToken} from '../models/User';
import { BaseResponse } from '../models/BaseResponse';

class UserService extends BaseApiService {
  constructor() {
    super(process.env.REACT_APP_API_URL as string);
  }
  
  async createUser(user: UserRegister): Promise<AxiosResponse<BaseResponse<string>>> {
    return await this.post<BaseResponse<string>>('/users', user);
  }

  async confirmEmail(user: UserConfirmEmail) : Promise<AxiosResponse<BaseResponse<string>>>{ 
    return await this.post(`/users/${user.id}/confirm-email`, user);
  }

  async resendEmail(email: string): Promise<AxiosResponse<BaseResponse<string>>> {
    return await this.post<BaseResponse<string>>('/users/resend-email', {email});
  }

  async loginUser(user: UserLogin): Promise<AxiosResponse<BaseResponse<UserToken>>> {
    return await this.post<BaseResponse<UserToken>>('/users/login', user);
  }

  async getUser(id: string): Promise<AxiosResponse<BaseResponse<User>>> {
    return await this.get<BaseResponse<User>>(`/users/${id}`);	
  }

  async getAllUsers(): Promise<AxiosResponse<BaseResponse<User[]>>>{ 
    return await this.get<BaseResponse<User[]>>('/users');
  }

  async updateUser(user: User): Promise<AxiosResponse<BaseResponse<string>>>{ 
    return await this.put<BaseResponse<string>>(`/users/${user.id}`, user);
  }

  async deleteUser(id: string): Promise<AxiosResponse<BaseResponse<string>>>{ 
    return await this.delete<BaseResponse<string>>(`/users/${id}`);
  }

}

const userService = new UserService();

export default userService;
