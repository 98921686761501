import React from 'react';
import { MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';

interface CurrencyOption {
  value: string;
  label: string;
  symbol: string;
  flag: string;
}

const CURRENCY_OPTIONS: CurrencyOption[] = [
  { value: 'BRL', label: 'Brazilian Real', symbol: 'R$', flag: 'https://flagcdn.com/w320/br.png' },
  { value: 'USD', label: 'United States Dollar', symbol: '$', flag: 'https://flagcdn.com/w320/us.png' },
  { value: 'EUR', label: 'Euro', symbol: '€', flag: 'https://flagcdn.com/w320/eu.png' }
];

interface CurrencyOptionsProps {
  value: string;
  onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  label: string;
}

const CurrencyOptions: React.FC<CurrencyOptionsProps> = ({ value, onChange, label }) => (
  <FormControl fullWidth variant="outlined" margin="dense" sx={{ height: '56px' }}>
    <InputLabel>{label}</InputLabel>
    <Select
      value={value}
      onChange={onChange}
      label={label}
      sx={{ height: '100%' }}
    >
      {CURRENCY_OPTIONS.map(option => (
        <MenuItem key={option.value} value={option.value}>
          <img src={option.flag} alt={`${option.value} flag`} width="20" height="15" style={{ marginRight: 8 }} />
          {option.symbol} {option.value} - {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default CurrencyOptions;

export { CURRENCY_OPTIONS };