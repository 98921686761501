import React, { useState } from 'react';
import { Typography, TextField, Button, Box, IconButton, InputAdornment, Link } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link as RouterLink , useNavigate } from 'react-router-dom';
import UserService from '../../apis/services/UserService';
import AlertMessage from '../../components/common/AlertMessage';
import { UserRegister } from '../../apis/models/User';
import BoxWrapper from '../../components/common/BoxWrapper';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const RegisterPage: React.FC = () => {
  const [formData, setFormData] = useState<UserRegister>({
    email: '',
    name: '',
    surname: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
  });
  
  const [errors, setErrors] = useState<string[]>([]);
  const [success, setSuccess] = useState<string[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handlePhoneChange = (value: string) => {
    setFormData({
      ...formData,
      phoneNumber: value
    });
  };

  const handleCloseError = (index: number) => {
    setErrors(prev => prev.filter((_, i) => i !== index));
  };

  const handleCloseSuccess = (index: number) => {
    setSuccess(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors([]);
    try {
      const response = await UserService.createUser(formData);
      if (response.data.success) {
        setSuccess([response.data.data || 'Registration successful! Please check your e-mail to confirm your account.']);
        setFormData({
          email: '',
          name: '',
          surname: '',
          phoneNumber: '',
          password: '',
          confirmPassword: ''
        });
        setTimeout(() => navigate(`/resend-email?email=${formData.email}`), 2000);
      } else {
        setErrors(response.data.errors || ['An unexpected error occurred.']);
      }
    } catch (error: any) {
      setErrors(error.response?.data?.errors || ['An unexpected error occurred.']);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BoxWrapper>
      <Typography variant="h5" gutterBottom>
        Welcome to Reuros Exchange!
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        Join a trusted network for direct, fee-free currency exchanges. Perfect for travelers and expatriates.
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ mb: 1 }}>
        Please register your account.
      </Typography>

      <Typography variant="body2" sx={{ mb: 3 }}>
        By registering, you agree to our <Link component={RouterLink} to="/terms">Terms of Use</Link> and <Link component={RouterLink} to="/privacy">Privacy Policy</Link>.
      </Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <AlertMessage messages={errors} severity="error" onClose={handleCloseError} />
        <AlertMessage messages={success} severity="success" onClose={handleCloseSuccess} />
        
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 1 }}>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Surname"
            name="surname"
            value={formData.surname}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 1 }}>
          <TextField
            label="E-mail"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            type="email"
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 1 }}>
          <PhoneInput
            country={'br'}
            value={formData.phoneNumber}
            onChange={handlePhoneChange}
            onlyCountries={['br', 'us', 'mt', 'gb', 'es', 'fr', 'de', 'it', 'pt', 'ie']}
            inputProps={{
              name: 'phoneNumber',
              required: true,
              autoFocus: false
            }}
            containerStyle={{ width: '100%' }}
            inputStyle={{ width: '100%', height: '56px' }}
            buttonStyle={{ left: '14px' }}  
            specialLabel="Phone Number"
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 1 }}>
          <TextField
            label="Password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            value={formData.confirmPassword}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} mt={2}>
          <Button type="submit" variant="contained" color="primary" size="large" sx={{ width: { xs: '100%', sm: 'auto' } }} disabled={isSubmitting}>
            Register
          </Button>
          <Button variant="text" color="primary" size="large" sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={() => navigate('/login')}>
            Already have an account? Login
          </Button>
        </Box>
      </Box>
    </BoxWrapper>
  );
};

export default RegisterPage;