import React from 'react';
import { Typography, Box, Link } from '@mui/material';
import BoxWrapper from '../../components/common/BoxWrapper';
import { Link as RouterLink } from 'react-router-dom';

const PolicyPage: React.FC = () => {
  return (
    <BoxWrapper>
      <Typography variant="h5" gutterBottom>
        Terms of Use
      </Typography>
      <Typography variant="body1">
        1. Introduction
      </Typography>
      <Typography variant="body2" paragraph>
        Welcome to Reuros Exchange! Our platform is designed to facilitate direct, secure, and transparent currency exchange between individuals. By accessing and using our services, you agree to comply with these Terms of Use.
      </Typography>
      <Typography variant="body1">
        2. Eligibility
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li>Be at least 18 years old.</li>
        <li>Create an account with accurate and up-to-date information.</li>
        <li>Ensure the security of your account and not share your credentials with third parties.</li>
      </Box>
      <Typography variant="body1">
        3. Prohibited Use
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li>Engage in illegal, fraudulent, or deceptive activities.</li>
        <li>Post or send offensive, discriminatory, or inappropriate content.</li>
        <li>Violate intellectual property rights or the privacy of others.</li>
        <li>Perform spam, phishing, or any form of manipulation on the platform.</li>
      </Box>
      <Typography variant="body1">
        4. User Verification
      </Typography>
      <Typography variant="body2" paragraph>
        To ensure a safe environment:
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li>All users must undergo a verification process before accessing services.</li>
        <li>Personal information provided during verification will be handled in accordance with our <Link component={RouterLink} to="/privacy">Privacy Policy</Link></li>
      </Box>
      <Typography variant="body1">
        5. Fees and Transactions
      </Typography>
      <Typography variant="body2" paragraph>
        Reuros Exchange does not charge fees for transactions between users. However:
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li>Users are responsible for any external costs, such as bank fees or other charges.</li>
        <li>Transactions must be conducted directly between the parties without platform involvement.</li>
      </Box>
      <Typography variant="body1">
        6. Rating and Reputation System
      </Typography>
      <Typography variant="body2" paragraph>
        After each transaction, users can:
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li>Rate and leave feedback on their experience.</li>
        <li>Build a reputation based on reviews and ratings.</li>
        <li>Reuros Exchange is not responsible for the accuracy or fairness of user reviews.</li>
      </Box>
      <Typography variant="body1">
        7. Reporting Suspicious Activity
      </Typography>
      <Typography variant="body2" paragraph>
        Security is a priority. If you detect suspicious activity:
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li>Use the tools available on the platform to report it.</li>
        <li>Reuros Exchange will investigate all reports and may take actions, including account suspension or banning.</li>
      </Box>
      <Typography variant="body1">
        8. User Responsibilities
      </Typography>
      <Typography variant="body2" paragraph>
        Users agree to:
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li>Conduct transactions with transparency and good faith.</li>
        <li>Assume full responsibility for transactions they perform.</li>
        <li>Indemnify Reuros Exchange for any misuse of the platform that causes harm or losses to others.</li>
      </Box>
      <Typography variant="body1">
        9. Limitation of Liability
      </Typography>
      <Typography variant="body2" paragraph>
        Reuros Exchange is an intermediary platform and is not responsible for:
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li>Disputes between users.</li>
        <li>Financial losses resulting from transactions conducted on the platform.</li>
        <li>Any indirect, special, or incidental damage related to the use of the service.</li>
      </Box>
      <Typography variant="body1">
        10. Changes to the Terms of Use
      </Typography>
      <Typography variant="body2" paragraph>
        Reuros Exchange may update these Terms of Use periodically.
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li>Changes will be communicated to users with reasonable notice.</li>
        <li>Continued use of the platform after the update implies acceptance of the new terms.</li>
      </Box>
      <Typography variant="body1">
        11. Account Termination
      </Typography>
      <Typography variant="body2" paragraph>
        Users may terminate their accounts at any time.
      </Typography>
      <Box component="ul" sx={{ pl: 3 }}>
        <li>Reuros Exchange reserves the right to suspend or terminate accounts that violate these Terms of Use or are involved in suspicious activities.</li>
      </Box>
      <Typography variant="body1">
        12. Contact
      </Typography>
      <Typography variant="body2" paragraph>
        For any questions or concerns about these Terms of Use, please contact us at <Link href="mailto:support@reurosexchange.com">support@reurosexchange.com</Link>
      </Typography>
      <Typography variant="body2" paragraph>
        Last updated: December 2024
      </Typography>
    </BoxWrapper>
  );
}

export default PolicyPage;