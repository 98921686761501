import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        mt: 'auto',
        py: 2,
        textAlign: 'center'
      }}
    >
      <Typography variant="body2">© 2024 Reuros Exchange. All rights reserved.</Typography>
    </Box>
  );
}

export default Footer;
