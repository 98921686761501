import React, { useEffect } from 'react';
import { Alert, Box } from '@mui/material';

interface AlertMessageProps {
  severity: 'error' | 'warning' | 'info' | 'success';
  messages: string[];
  onClose: (index: number) => void;
}

const AlertMessage: React.FC<AlertMessageProps> = ({ severity, messages, onClose }) => {
  useEffect(() => {
    if (severity === 'success') {
      const timers = messages.map((_, index) =>
        setTimeout(() => onClose(index), 4000)
      );
      return () => {
        timers.forEach(clearTimeout);
      };
    }
  }, [messages, onClose, severity]);

  return (
    <>
      {messages.length > 0 &&
        messages.map((msg, index) => (
          <Box key={index} sx={{ mb: 1 }}>
            <Alert severity={severity} onClose={() => onClose(index)}>
              {msg}
            </Alert>
          </Box>
        ))}
    </>
  );
};

export default AlertMessage;
