import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { Typography, ListItemIcon, Paper, Grid, TextField, Box, InputAdornment, Button, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import BoxWrapper from '../../components/common/BoxWrapper';
import feedService from '../../apis/services/FeedService';
import { useAuth } from '../../hooks/AuthProvider';
import CurrencyOptions, { CURRENCY_OPTIONS } from '../../components/common/CurrencyOptions';
import AlertMessage from '../../components/common/AlertMessage';
import moment from '../../utils/Moment';
import { CurrencyFormatter } from '../../utils/CurrencyFormatter';
import { Rates } from '../../apis/models/ExchangeRate';
import tradeService from '../../apis/services/TradeService';
import { Trade } from '../../apis/models/Trade';

interface ConvertedAmounts {
  [key: string]: number;
}

function ConverterPage() {
  const { isAuthenticated: isLoggedIn } = useAuth();
  const [rates, setRates] = useState<Rates[]>([]);
  const [amount, setAmount] = useState<string>('0,00');
  const [inputCurrency, setInputCurrency] = useState<string>('BRL');
  const [convertedAmounts, setConvertedAmounts] = useState<ConvertedAmounts>({});
  const [lastUpdated, setLastUpdated] = useState<string>('');
  const [apiStatus, setApiStatus] = useState<boolean>(false);
  const [apiUrl] = useState<string>('https://www.exchangerate-api.com/');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [success, setSuccess] = useState<string[]>([]);

  const fetchRates = useCallback(async () => {
    try {
      const response = await feedService.getExchangeRates(inputCurrency);
      setApiStatus(true);
      const data = response.data.data;
      const rates: Rates[] = data.rates.map((rate: any) => ({ currency: rate.currency, rate: rate.rate }));
      setRates(rates);
      setLastUpdated(moment(data.lastUpdated).format('DD/MM/YYYY HH:mm:ss'));
    } catch (error) {
      setApiStatus(false);
      const zeroRates: Rates[] = CURRENCY_OPTIONS.map(option => ({ currency: option.value, rate: 0 }));
      setRates(zeroRates);
      setLastUpdated(new Date().toLocaleString());
    }
  }, [inputCurrency]);

  useEffect(() => {
    fetchRates();
  }, [fetchRates]);

  useEffect(() => {
    setConvertedAmounts(calculateConvertedAmounts(rates, amount));
  }, [rates, amount]);

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsUpdating(true);
    let value = e.target.value.replace(/\D/g, '');
    if (value === '') value = '0';
    const numericValue = parseFloat(value) / 100;
    const formattedValue = numericValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
    setAmount(formattedValue);
    setConvertedAmounts(calculateConvertedAmounts(rates, formattedValue));
    setIsUpdating(false);
  };

  const calculateConvertedAmounts = (rates: Rates[], amount: string): ConvertedAmounts => {
    const numericAmount = parseFloat(amount.replace(',', '.').replace(/\./g, '') || '0') / 100;
    const ratesMap = Object.fromEntries(rates.map(rate => [rate.currency, rate.rate]));
    return rates.reduce((acc, { currency, rate }) => {
      acc[currency] = numericAmount * (ratesMap[currency] || 0);
      return acc;
    }, {} as ConvertedAmounts);
  };

  const handleTrade = async (TradeType: string) => {
    try {
      const trade: Trade = {
        type: TradeType.toUpperCase(),
        currency: inputCurrency,
        amount: parseFloat(amount.replace(',', '.').replace(/\./g, '') || '0') / 100
      };
      const response = await tradeService.createTrade(trade);
      setErrors([]);
      setSuccess([response.data.data || `${TradeType} trade registered successfully!`]);
    } catch (error: any) {
      setSuccess([]);
      const serverErrors = error.response?.data?.errors || [];
      const errorMessage = serverErrors.length > 0 ? serverErrors[0] : error.message || 'An error occurred';
      setErrors([errorMessage]);
    }
  };

  return (
    <BoxWrapper>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ mb: 1 }}>Currency Exchange</Typography>
          <AlertMessage messages={errors} severity="error" onClose={(index) => setErrors(errors.filter((_, i) => i !== index))} />
          <AlertMessage messages={success} severity="success" onClose={(index) => setSuccess(success.filter((_, i) => i !== index))} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CurrencyOptions
            value={inputCurrency}
            onChange={(e) => setInputCurrency(e.target.value)}
            label="Currency"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Amount"
            variant="outlined"
            fullWidth
            value={amount}
            onChange={handleAmountChange}
            margin="dense"
            InputProps={{
              startAdornment: <InputAdornment position="start">{CURRENCY_OPTIONS.find(option => option.value === inputCurrency)?.symbol}</InputAdornment>,
            }}
            sx={{ height: '56px' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Tooltip title={isLoggedIn ? 'This action will generate a publish with your intention to buy, allowing other users to offer.' : 'Please login to publish your intention to buy, allowing other users to offer.'}>
            <span style={{ display: 'block', width: '100%' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                sx={{ height: '56px', mt: '4px' }}
                onClick={() => handleTrade('buy')}
                disabled={!isLoggedIn}
              >
                Buy
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Tooltip title={isLoggedIn ? 'This action will generate a publish with your intention to sell, allowing other users to offer.' : 'Please login to publish your intention to sell, allowing other users to offer.'}>
            <span style={{ display: 'block', width: '100%' }}>
              <Button
                variant="contained"
                color="success"
                size="large"
                fullWidth
                sx={{ height: '56px', mt: '4px' }}
                onClick={() => handleTrade('sell')}
                disabled={!isLoggedIn}
              >
                Sell
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>      
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ mb: 1 }}>Exchange Rates for {CURRENCY_OPTIONS.find(option => option.value === inputCurrency)?.label}</Typography>
        </Grid>
        {rates.map(({ currency, rate }) => (
          <Grid item xs={12} sm={6} md={4} key={currency}>
            <Paper variant="outlined" sx={{ p: 1, borderWidth: '1px', display: 'flex', alignItems: 'center' }}>
              <ListItemIcon sx={{ minWidth: 'auto', mx: 1 }}>
                <img
                  src={CURRENCY_OPTIONS.find(option => option.value === currency)?.flag}
                  alt={`${currency} flag`}
                  width="40"
                  height="30"
                />
              </ListItemIcon>
              <Box sx={{ ml: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{CURRENCY_OPTIONS.find(option => option.value === currency)?.label}</Typography>
                <Typography variant="body2">{CurrencyFormatter(rate, currency)}</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {CurrencyFormatter(parseFloat(amount.replace(',', '.').replace(/\./g, '')) / 100, inputCurrency)} = {CurrencyFormatter(convertedAmounts[currency] || '0.00', currency)}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" sx={{ opacity: 0.6, flexDirection: { xs: 'column', sm: 'row' }, overflowX: 'auto' }}>
            <Typography variant="body2" display="flex" alignItems="center" component="div" >
              <strong>Last updated: </strong> <span style={{ marginLeft: '4px' }}>{lastUpdated}</span> <strong style={{ marginLeft: '8px' }}>Source: </strong> <span style={{ marginLeft: '4px' }}><a href={apiUrl} target="_blank" rel="noopener noreferrer">Exchange Rate API</a></span>
              {apiStatus ? (
                <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
                  <Typography variant="body2" component="span"><strong>Status: </strong> OK</Typography>
                  <CheckCircleIcon color="success" sx={{ ml: 0.5 }} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
                  <Typography variant="body2" component="span"><strong>Status: </strong> NOK</Typography>
                  <ErrorIcon color="error" sx={{ ml: 0.5 }} />
                  <Typography variant="body2" sx={{ ml: 1 }} component="span">It was not possible to retrieve the exchange rates at the moment.</Typography>
                </Box>
              )}
            </Typography>
            {isUpdating && (
              <Typography variant="body2" sx={{ ml: 2 }}>
                Updating...
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </BoxWrapper>
  );
}

export default ConverterPage;