import React, { useState, useEffect, ReactNode } from 'react';
import { Box, CssBaseline, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Mobile from '../mobile/Mobile';
import Desktop from '../desktop/Desktop';
import { useAuth } from '../../hooks/AuthProvider';

interface LayoutProps {
  isLoggedIn: boolean;
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ isLoggedIn, children }) => {
  const { isAuthenticated, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [location]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  if (loading) {
    return null; // or a loading spinner
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />
      {isMobile ? (
        <Mobile isLoggedIn={isAuthenticated} handleLogout={handleLogout}>
          {children}
        </Mobile>
      ) : (
        <Desktop isLoggedIn={isAuthenticated} handleLogout={handleLogout}>
          {children}
        </Desktop>
      )}
    </Box>
  );
};

export default Layout;
