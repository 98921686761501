import React, { useEffect, useState, useCallback } from 'react';
import BoxWrapper from '../../components/common/BoxWrapper';
import { Typography, Button, Tooltip, Table, TableBody, TableCell, TableHead, TableRow, Chip, Grid, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';
import { CURRENCY_OPTIONS } from '../../components/common/CurrencyOptions';
import { useAuth } from '../../hooks/AuthProvider';	
import VerifiedIcon from '@mui/icons-material/Verified';
import tradeService from '../../apis/services/TradeService';
import feedService from '../../apis/services/FeedService';
import RemoveIcon from '@mui/icons-material/Remove';
import RecommendIcon from '@mui/icons-material/Recommend';
import moment from '../../utils/Moment';	
import { CurrencyFormatter } from '../../utils/CurrencyFormatter';
import { Trade } from '../../apis/models/Trade';
import AlertMessage from '../../components/common/AlertMessage';


function FeedPage() {
  const { isAuthenticated: isLoggedIn, user } = useAuth();
  const [trades, setTrades] = useState<Trade[]>([]);
  const [sortTrade, setSortTrade] = useState<'asc' | 'desc'>('asc');
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTradeId, setSelectedTradeId] = useState<string | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [success, setSuccess] = useState<string[]>([]);

  const fetchTrades = useCallback(async () => {
    try {
      const response = await feedService.getOpenTrades();
      const sortedTrades = response.data.data.sort((a: Trade, b: Trade) => new Date(a.date ?? '').getTime() - new Date(b.date ?? '').getTime());     
      setTrades(sortedTrades);
      setLoading(false);
    } catch (error) {
      setErrors(['Failed to fetch trades. Please try again later.']);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchTrades();
    }, 1000);

    tradeService.subscribeToTradeUpdates(fetchTrades);

    return () => {
        tradeService.subscribeToTradeUpdates(() => {});
    };
  }, [fetchTrades]);

  const getCurrencyDetails = (currency: string) => {
    return CURRENCY_OPTIONS.find(opt => opt.value === currency) || {};
  };

  const getUserDetails = (user: { rating?: number }) => {
    return `Rating: ${user?.rating !== undefined ? user.rating : 'N/A'}%`;
  };

  const handleDialogOpen = (tradeId: string) => {
    setSelectedTradeId(tradeId);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedTradeId(null);
  };

  const handleConfirmRemove = async () => {
    try {
      const response = await tradeService.deleteTrade(selectedTradeId!);
      setTrades(prevTrades => prevTrades.filter(trade => trade.id !== selectedTradeId));
      setSuccess([response.data.data || 'Trade deleted successfully!']);
      handleDialogClose();
    } catch (error: any) {
      const serverErrors = error.response?.data?.errors || [];
      const errorMessage = serverErrors.length > 0 ? serverErrors[0] : error.message || 'An error occurred';
      setErrors([errorMessage]);
    }
  };

  const handleTradeClick = (tradeId: string, isOwner: boolean) => {
    if (isOwner) {
      handleDialogOpen(tradeId);
    } else {
      // Implement trade logic here
      console.log(`Trade button clicked for trade ${tradeId}`);
    }
  };

  const handleSortTradeChange = () => {
    setSortTrade(prevTrade => (prevTrade === 'asc' ? 'desc' : 'asc'));
    setTrades(prevTrades => [...prevTrades].sort((a, b) => {
      return sortTrade === 'asc' 
        ? new Date(a.date ?? '').getTime() - new Date(b.date ?? '').getTime() 
        : new Date(b.date ?? '').getTime() - new Date(a.date ?? '').getTime();
    }));
  };

  const renderCurrencyFlag = (currency: string) => {
    const details = getCurrencyDetails(currency);
    if ('flag' in details) {
      return <img src={details.flag as string} alt={`${currency} flag`} width="20" height="15" style={{ marginRight: 8 }} />;
    }
    return null;
  };

  return (
    <BoxWrapper>
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>Exchange Feed</Typography>
      <AlertMessage messages={errors} severity="error" onClose={(index) => setErrors(errors.filter((_, i) => i !== index))} />
      <AlertMessage messages={success} severity="success" onClose={(index) => setSuccess(success.filter((_, i) => i !== index))} />
      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              {trades.length > 0 && (
                <Button onClick={handleSortTradeChange} size="small">
                  Date {sortTrade === 'asc' ? '▲' : '▼'}
                </Button>
              )}
            </Grid>
          </Grid>
          <Box sx={{ overflowX: 'auto' }}>
          <Table>
            {trades.length > 0 && (
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '15%' }}>Date</TableCell>
                  <TableCell sx={{ width: '25%' }}>Advertiser</TableCell>
                  <TableCell sx={{ width: '20%' }}>Type</TableCell>
                  <TableCell sx={{ width: '20%' }}>Currency</TableCell>
                  <TableCell sx={{ width: '20%' }}>Amount</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {trades.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No trade options available at the moment.
                  </TableCell>
                </TableRow>
              ) : (
                trades.map(trade => {
                  const flag = renderCurrencyFlag(trade.currency);
                  return (
                    <TableRow key={trade.id}>
                      <TableCell>{moment(trade.date).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                      <TableCell>
                        <span style={{ filter: isLoggedIn ? 'none' : 'blur(4px)', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          {isLoggedIn ? (
                            <>
                              <span style={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title="Verified User">
                                  <VerifiedIcon color="primary" fontSize="small" sx={{ mr: 0.5 }} />
                                </Tooltip>
                                <span>{trade.user?.name}</span>
                              </span>
                              <Tooltip title={trade.user ? getUserDetails(trade.user) : ''}>
                                <span style={{ display: 'flex', alignItems: 'center', color: 'gray' }}>
                                  <RecommendIcon fontSize="small" sx={{ mr: 0.5 }} /> {trade.user?.rating !== undefined ? trade.user.rating : 'N/A'}%
                                </span>
                              </Tooltip>
                            </>
                          ) : '******'}
                        </span>
                      </TableCell>
                      <TableCell>
                        <Chip 
                          label={trade.type} 
                          style={{ 
                            backgroundColor: trade.type === 'SELL' ? 'rgba(0, 128, 0, 0.2)' : 'rgba(25, 118, 210, 0.2)', 
                            color: 'black' 
                          }} 
                        />
                      </TableCell>
                      <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {flag}
                          {trade.currency}
                        </div>
                      </TableCell>
                      <TableCell>{CurrencyFormatter(trade.amount, trade.currency)}</TableCell>
                      <TableCell>
                        <Tooltip title={isLoggedIn ? '' : 'Please login to trade'}>
                          <span>
                            <Button 
                              variant={trade.user?.id === user?.id ? 'outlined' : 'contained'} 
                              color={trade.user?.id === user?.id ? 'error' : 'success'} 
                              size='small'
                              fullWidth
                              onClick={() => trade.id && handleTradeClick(trade.id, trade.user?.id === user?.id)}
                              disabled={!isLoggedIn}
                              startIcon={isLoggedIn && trade.user?.id === user?.id ? <RemoveIcon /> : null}
                            >
                              {isLoggedIn ? (trade.user?.id === user?.id ? 'Remove' : 'Trade') : 'Trade'}
                            </Button>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
          </Box>
        </>
      )}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this trade? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmRemove} color="error">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </BoxWrapper> 
  );
}

export default FeedPage;