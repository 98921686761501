import React from 'react';
import { Breadcrumbs, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
  pathnames: string[];
}

function Breadcrumb({ pathnames }: BreadcrumbProps) {
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
      <MuiLink component={Link} to="/" color="inherit">
        Home
      </MuiLink>
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        return (
          <MuiLink key={to} component={Link} to={to} color="inherit">
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </MuiLink>
        );
      })}
    </Breadcrumbs>
  );
}

export default Breadcrumb;