import { AxiosResponse } from "axios";
import { BaseResponse } from "../models/BaseResponse";
import { ExchangeRate } from "../models/ExchangeRate";
import BaseApiService from "./BaseApiService";
import { Trade } from "../models/Trade";
class FeedService extends BaseApiService { 

  constructor() {
    super(process.env.REACT_APP_API_URL as string);
  } 

  async getExchangeRates(currency: string): Promise<AxiosResponse<BaseResponse<ExchangeRate>>> {
    return await this.get<BaseResponse<ExchangeRate>>(`/feeds/exchange-rates?currency=${currency}`);	
  } 

  async getOpenTrades(): Promise<AxiosResponse<BaseResponse<Trade[]>>> {
    return await this.get<BaseResponse<Trade[]>>(`/feeds/open-trades`);
  }
  
}

const feedService = new FeedService();

export default feedService;

