import React, { useEffect, useState, useCallback } from 'react';
import { Typography, TextField, Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tabs, Tab, Grid, FormControl, InputLabel, Select, MenuItem, Table, TableHead, TableRow, TableCell, TableBody, Chip, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../../components/common/AlertMessage';
import BoxWrapper from '../../components/common/BoxWrapper';
import userService from '../../apis/services/UserService';
import tradeService from '../../apis/services/TradeService';
import { useAuth } from '../../hooks/AuthProvider';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CurrencyOptions, { CURRENCY_OPTIONS } from '../../components/common/CurrencyOptions';
import moment from '../../utils/Moment';
import { CurrencyFormatter } from '../../utils/CurrencyFormatter';
import { User, UserAccount } from '../../apis/models/User';
import { Trade } from '../../apis/models/Trade';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const AccountType = {
  PIX: 'PIX',
  MBWAY: 'MBWAY',
  REVOLUT: 'REVOLUT',
  WISE: 'WISE'
};

const getCurrencyDetails = (currency: string) => {
  return CURRENCY_OPTIONS.find(opt => opt.value === currency) || {};
};

function ProfilePage() {
  const navigate = useNavigate();
  const { user: currentUser, isAuthenticated: isLoggedIn, logout } = useAuth();

  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [accounts, setAccounts] = useState<UserAccount[]>([{ id: '', currency: '', address: '', type: '' }]);
  const [error, setErrorState] = useState<string[]>([]);
  const [success, setSuccessState] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tradeHistory, setTradeHistory] = useState<Trade[]>([]);
  const [tradeToDelete, setTradeToDelete] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const fetchProfile = useCallback(async () => {
    if (!currentUser || !currentUser.id) return;
    try {
      const response = await userService.getUser(currentUser.id);
      const userData = response.data.data;
      setName(userData.name || '');
      setSurname(userData.surname || '');
      setEmail(userData.email || '');
      setPhoneNumber(userData.phoneNumber || '');
      setAccounts(userData.accounts.length ? userData.accounts : [{ id: '', currency: '', address: '', type: '' }]);
    } catch (err) {
      setErrorState(['Failed to load profile.']);
    }
  }, [currentUser]);

  const fetchTradeHistory = useCallback(async () => {
    if (!currentUser || !currentUser.id) return;
    try {
      const response = await tradeService.getTrades({ userId: currentUser.id });
      setTradeHistory(response.data.data || []);
    } catch (err) {
      setErrorState(['Failed to load trade history.']);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    if (!currentUser || !currentUser.id) {
      setErrorState(['User ID is missing.']);
      return;
    }

    fetchProfile();
  }, [isLoggedIn, navigate, currentUser, fetchProfile]);

  useEffect(() => {
    if (tabIndex === 2) {
      fetchTradeHistory();
    }
  }, [tabIndex, fetchTradeHistory]);

  const handleAddAccount = useCallback(() => {
    setAccounts([...accounts, { id: '', currency: '', address: '', type: '' }]);
  }, [accounts]);

  useEffect(() => {
    if (accounts.length === 0) {
      handleAddAccount();
    }
  }, [accounts.length, handleAddAccount]);

  const handleAccountChange = (index: number, field: string, value: string) => {
    const newAccounts = [...accounts];
    (newAccounts[index] as any)[field] = value;
    setAccounts(newAccounts);
  };

  const handlePhoneChange = (value: string) => {
    setPhoneNumber(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setErrorState([]);
    setSuccessState([]);

    const validAccounts = accounts.filter(account => {
      if (account.currency) {
        return account.address && account.type;
      }
      return false;
    });

    if (validAccounts.length !== accounts.length && accounts.some(account => account.currency || account.address || account.type)) {
      setErrorState(['Please fill in all required fields for each account.']);
      setIsSubmitting(false);
      return;
    }

    const updatedUser: User = { 
      id: currentUser?.id || '', 
      name, 
      surname, 
      email, 
      phoneNumber, 
      accounts: validAccounts.length ? validAccounts.map(account => {
        const { id, ...rest } = account;
        return id ? { id, ...rest } : { ...rest };
      }) : [] 
    };

    try {
      await userService.updateUser(updatedUser);
      setSuccessState(['Profile updated successfully!']);
      await fetchProfile();
    } catch (error) {
      setErrorState(['Failed to update profile.']);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    if (!currentUser || !currentUser.id) {
      setErrorState(['User ID is missing.']);
      return;
    }
    try {
      await userService.deleteUser(currentUser.id);
      logout();
      navigate('/login');
    } catch (err) {
      setErrorState(['Failed to delete profile.']);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleRemoveAccount = (index: number) => {
    const newAccounts = accounts.filter((_, i) => i !== index);
    setAccounts(newAccounts);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemoveTrade = (tradeId: string) => {
    setTradeToDelete(tradeId);
    setOpen(true);
  };

  const confirmRemoveTrade = async () => {
    try {
      const response = await tradeService.deleteTrade(tradeToDelete!);
      setTradeHistory((prev) => prev.filter((trade) => trade.id !== tradeToDelete));
      setSuccessState([response.data.data || 'Trade deleted successfully!']);
      setTradeToDelete(null);
      setOpen(false);
    } catch (err) {
      setErrorState(['Failed to remove trade.']);
    }
  };

  const renderCurrencyFlag = (currency: string) => {
    const details = getCurrencyDetails(currency);
    if ('flag' in details) {
      return <img src={details.flag as string} alt={`${currency} flag`} width="20" height="15" style={{ marginRight: 8 }} />;
    }
    return null;
  };

  return (
    <BoxWrapper>
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        Profile
      </Typography>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="profile tabs"
        sx={{ mt: 2 }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Profile Information" />
        <Tab label="Banking Accounts" />
        <Tab label="Trading History" />
      </Tabs>

      <Box sx={{ mt: 2 }} component="form" onSubmit={handleSubmit}>
        {tabIndex === 0 && (
          <Box>
            <AlertMessage messages={error} severity="error" onClose={(index) => setErrorState((prev) => prev.filter((_, i) => i !== index))} />            
            <AlertMessage messages={success} severity="success" onClose={(index) => setSuccessState((prev) => prev.filter((_, i) => i !== index))} />
            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} mb={1}>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Surname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                fullWidth
                margin="normal"
                required
              />
            </Box>
            <TextField
              label="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              required
              type="email"
              sx={{ mb: 1 }}
            />
            <PhoneInput
              country={'br'}
              value={phoneNumber}
              onChange={handlePhoneChange}
              onlyCountries={['br', 'us', 'mt', 'gb', 'es', 'fr', 'de', 'it', 'pt', 'ie']}
              inputProps={{
                name: 'phoneNumber',
                required: true,
                autoFocus: false
              }}
              containerStyle={{ width: '100%' }}
              inputStyle={{ width: '100%', height: '56px' }}
              buttonStyle={{ left: '14px' }}  
              specialLabel="Phone Number"
            />
          </Box>
        )}
        {tabIndex === 1 && (
          <Box>
            <AlertMessage messages={error} severity="error" onClose={(index) => setErrorState((prev) => prev.filter((_, i) => i !== index))} />           
            <AlertMessage messages={success} severity="success" onClose={(index) => setSuccessState((prev) => prev.filter((_, i) => i !== index))} />
            {accounts.length === 0 && (
              <Typography>No accounts available. Please add an account.</Typography>
            )}
            {accounts.map((account, index) => (
              <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }} key={index}>
                <Grid item xs={12} sm={3}>
                  <CurrencyOptions
                    value={account.currency}
                    onChange={(e) => handleAccountChange(index, 'currency', e.target.value)}
                    label="Currency"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth variant="outlined" margin="dense" sx={{ height: '56px' }}>
                    <InputLabel id={`account-type-label-${index}`}>Account Type</InputLabel>
                    <Select
                      labelId={`account-type-label-${index}`}
                      id={`account-type-${index}`}
                      value={account.type}
                      onChange={(e) => handleAccountChange(index, 'type', e.target.value)}
                      label="Account Type"
                      sx={{ height: '100%' }}
                    >
                      {Object.values(AccountType).map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Address"
                    value={account.address}
                    onChange={(e) => handleAccountChange(index, 'address', e.target.value)}
                    margin="dense"
                    fullWidth
                    sx={{ height: '56px' }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: { xs: 2, sm: 0 } }}>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    fullWidth
                    sx={{ height: '56px', mr: 1, mt: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    onClick={() => handleRemoveAccount(index)}
                    disabled={accounts.length === 1}
                    startIcon={<RemoveIcon />}
                  >
                    Remove
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    fullWidth
                    sx={{ height: '56px', mt: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}            
                    onClick={handleAddAccount}
                    disabled={accounts.length >= 3}
                    startIcon={<AddIcon />}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Box>
        )}

        {tabIndex === 2 && (
          <Box>
            <AlertMessage messages={error} severity="error" onClose={(index) => setErrorState((prev) => prev.filter((_, i) => i !== index))} />           
            <AlertMessage messages={success} severity="success" onClose={(index) => setSuccessState((prev) => prev.filter((_, i) => i !== index))} />
            {tradeHistory.length === 0 ? (
              <Typography>No trade history available.</Typography>
            ) : (
              <Box sx={{ overflowX: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Currency</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tradeHistory.map((trade) => (
                      <TableRow key={trade.id}>
                        <TableCell>{moment(trade.date).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                        <TableCell>{trade.type}</TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            {renderCurrencyFlag(trade.currency)}
                            {trade.currency}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {CurrencyFormatter(trade.amount, trade.currency)}
                        </TableCell>
                        <TableCell>
                          <Chip 
                            label={trade.status} 
                            color={trade.status === 'OPEN' ? 'primary' : 'secondary'} 
                            style={{ backgroundColor: trade.status === 'OPEN' ? 'rgba(0, 128, 0, 0.2)' : 'rgba(255, 0, 0, 0.2)', color: 'black' }}
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip title={trade.status !== 'OPEN' ? 'Cannot remove this trade' : ''}>
                            <span>
                              <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={() => trade.id && handleRemoveTrade(trade.id)}
                                startIcon={<RemoveIcon />}
                                disabled={trade.status !== 'OPEN'}
                              >
                                Remove
                              </Button>
                            </span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Box>
        )}

        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} mt={2}>
          <Button type="submit" variant="contained" color="primary" size="large" sx={{ width: { xs: '100%', sm: 'auto' } }} disabled={isSubmitting}>
            Save Changes
          </Button>
          <Button variant="outlined" color="error" size="large" sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={handleClickOpen}>
            Delete Profile
          </Button>
        </Box>
      </Box>

      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {tradeToDelete ? "Are you sure you want to remove this trade? This action cannot be undone." : "Are you sure you want to delete your profile? This action cannot be undone."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={tradeToDelete ? confirmRemoveTrade : handleDelete} color="error" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </BoxWrapper>
  );
}

export default ProfilePage;
