import React, { useContext, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { AuthContext } from './hooks/AuthProvider';
import RegisterPage from './pages/users/RegisterPage'; 
import Layout from './components/layout/Layout';
import ResendPage from './pages/users/ResendPage';
import AboutPage from './pages/about/AboutPage';
import LoginPage from './pages/users/LoginPage';
import ConfirmPage from './pages/users/ConfirmPage';
import ProfilePage from './pages/users/ProfilePage';
import AdminPage from './pages/admin/AdminPage';
import HomePage from './pages/home/HomePage';
import PrivacyPage from './pages/about/PrivacyPage';
import PolicyPage from './pages/about/PolicyPage';

const App: React.FC = () => {
  const authContext = useContext(AuthContext);
  
  const location = useLocation();

  const getTitle = (pathname: string): string => {
    switch (pathname) {
      case '/':
        return 'Home | Reuros Exchange';
      case '/register':
        return 'Register | Reuros Exchange';
      case '/login':
        return 'Login | Reuros Exchange';
      case '/profile':
        return 'Profile | Reuros Exchange';
      case '/about':
        return 'About | Reuros Exchange';
      case '/confirm-email':
        return 'Confirm E-mail | Reuros Exchange';
      case '/resend-email':
        return 'Resend E-mail | Reuros Exchange';
      case '/admin':
        return 'Admin Page | Reuros Exchange';
      default:
        return 'Reuros Exchange';
    }
  };

  useEffect(() => {
    document.title = getTitle(location.pathname);
  }, [location.pathname]);

  if (!authContext) {
    return <div>Loading...</div>;
  }

  const { user, loading } = authContext;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout isLoggedIn={!!user}>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<PageTransition><HomePage /></PageTransition>} />
          <Route path="register" element={<PublicRoute isLoggedIn={!!user}><PageTransition><RegisterPage /></PageTransition></PublicRoute>} />
          <Route path="resend-email" element={<PublicRoute isLoggedIn={!!user}><PageTransition><ResendPage /></PageTransition></PublicRoute>} />
          <Route path="confirm-email" element={<PublicRoute isLoggedIn={!!user}><PageTransition><ConfirmPage /></PageTransition></PublicRoute>} />
          <Route path="login" element={<PublicRoute isLoggedIn={!!user}><PageTransition><LoginWithContext /></PageTransition></PublicRoute>} />
          <Route path="profile" element={<PrivateRoute isLoggedIn={!!user}><PageTransition><ProfilePage /></PageTransition></PrivateRoute>} />
          <Route path="about" element={<PageTransition><AboutPage /></PageTransition>} />
          <Route path="terms" element={<PageTransition><PolicyPage /></PageTransition>} />
          <Route path="privacy" element={<PageTransition><PrivacyPage /></PageTransition>} />
          <Route path="admin" element={<PrivateRoute isLoggedIn={!!user}><PageTransition><AdminPage /></PageTransition></PrivateRoute>} />
        </Routes>
      </AnimatePresence>
    </Layout>
  );
};

const PageTransition: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.2, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
};

const PrivateRoute: React.FC<{ children: React.ReactNode, isLoggedIn: boolean }> = ({ children, isLoggedIn }) => {
  return isLoggedIn ? <>{children}</> : <Navigate to="/login" />;
}

const PublicRoute: React.FC<{ children: React.ReactNode, isLoggedIn: boolean }> = ({ children, isLoggedIn }) => {
  return !isLoggedIn ? <>{children}</> : <Navigate to="/" />;
};

function LoginWithContext() {
  return <LoginPage />;
}

export default App;
