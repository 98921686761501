import React, { FC } from 'react';
import ConverterPage from './ConverterPage';
import FeedPage from './FeedPage';


const HomePage: FC = () => {
  return (
    <>
      <ConverterPage />
      <FeedPage />
    </>
  );
};

export default HomePage;
