import React, { useEffect, useState, JSX } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp';
import LoginSharpIcon from '@mui/icons-material/LoginSharp';
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../hooks/AuthProvider';

const colorChange = keyframes`
  0% { color: #009c3b; } /* Green from Brazil */
  33% { color: #b22234; } /* Red from United States */
  66% { color: #003399; } /* Dark Blue from European Union */
  100% { color: #009c3b; } /* Green from Brazil */
`;

const AnimatedLetter = styled('span')`
  display: inline-block;
  animation: ${colorChange} 5s infinite;
`;

const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

interface HeaderProps {
  isLoggedIn: boolean;
  handleLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ handleLogout }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isAuthenticated } = useAuth();
  const [loggedIn, setLoggedIn] = useState<boolean>(isAuthenticated);

  useEffect(() => {
    setLoggedIn(isAuthenticated);
  }, [isAuthenticated]);

  const renderNavButtons = (): JSX.Element => {
    const buttonStyles = {
      '&:hover': { backgroundColor: theme.palette.action.hover },
      fontWeight: 'bold',
    };

    return (
      <>
        {loggedIn ? (
          <>
            <Button
              color="primary"
              variant="text"
              component={Link}
              to="/profile"
              startIcon={<AccountBoxSharpIcon />}
              sx={buttonStyles}
            >
              Profile
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleLogout}
              startIcon={<LogoutSharpIcon />}
              sx={buttonStyles}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button
              color="primary"
              variant="text"
              component={Link}
              to="/register"
              startIcon={<PersonAddAltSharpIcon />}
              sx={buttonStyles}
            >
              Register
            </Button>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/login"
              startIcon={<LoginSharpIcon />}
              sx={buttonStyles}
            >
              Login
            </Button>
          </>
        )}
      </>
    );
  };

  const renderAnimatedText = (text: string): JSX.Element[] => {
    return text.split('').map((letter, index) => (
      <AnimatedLetter key={index} style={{ animationDelay: `${index * 0.3}s` }}>
        {letter}
      </AnimatedLetter>
    ));
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        background: theme.palette.background.paper,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        ...(isMobile && { padding: '0 10px' }),
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', px: 2, ...(isMobile && { px: 1 }) }}>
        {/* Logo / Menu */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ...(isMobile && { flexGrow: 1, justifyContent: 'center' }) }}>
          <LogoContainer>
            <Typography
              variant="h6"
              component={Link}
              to="/"
              sx={{
                textDecoration: 'none',
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                ...(isMobile && { fontSize: '1.5rem' }),
              }}
            >
              <strong>Reuros {renderAnimatedText('Exchange')}</strong>
            </Typography>
          </LogoContainer>
          {!isMobile && (
            <Button
              color="primary"
              variant="text"
              component={Link}
              to="/about"
              sx={{
                '&:hover': { backgroundColor: theme.palette.action.hover },
              }}
            >
              About
            </Button>
          )}
        </Box>
        {/* Navigation Links */}
        {!isMobile && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {renderNavButtons()}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
