import { AxiosResponse } from 'axios';
import BaseApiService from './BaseApiService';
import { BaseResponse } from '../models/BaseResponse';
import { Trade } from '../models/Trade';

type TradeUpdateCallback = () => void;

class TradeService extends BaseApiService {
  
  private tradeUpdateCallback?: TradeUpdateCallback;

  constructor() {
    super(process.env.REACT_APP_API_URL as string);
  }

  public subscribeToTradeUpdates(callback: TradeUpdateCallback): void {
    this.tradeUpdateCallback = callback;
  }

  async createTrade(trade: Trade): Promise<AxiosResponse<BaseResponse<string>>> {
    const response = await this.post<BaseResponse<string>>('/trades', trade);
    
    if (this.tradeUpdateCallback) {
      this.tradeUpdateCallback();
    }
    
    return response;
  }

  async getTrades(params?: Record<string, string>): Promise<AxiosResponse<BaseResponse<Trade[]>>> {
    const queryString = params ? '?' + new URLSearchParams(params).toString() : '';
    return await this.get<BaseResponse<Trade[]>>(`/trades${queryString}`);
  }

  async deleteTrade(id: string): Promise<AxiosResponse<BaseResponse<string>>> {
    return await this.delete<BaseResponse<string>>(`/trades/${id}`);
  }
}

const tradeService = new TradeService();

export default tradeService;
