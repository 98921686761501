import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BoxWrapper from '../../components/common/BoxWrapper';
import { useAuth } from '../../hooks/AuthProvider';
import UserService from '../../apis/services/UserService';
import { Typography, Button, TextField, IconButton, Box } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AlertMessage from '../../components/common/AlertMessage';
import { UserLogin } from '../../apis/models/User';

interface LoginPageProps {
  onSuccess?: () => void;
  withoutCard?: boolean;
}

const LoginPage: React.FC<LoginPageProps> = ({ onSuccess, withoutCard }) => {
  const [formData, setFormData] = useState<UserLogin>({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string[]>([]);
  const [success, setSuccess] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setError([]);
    setSuccess('');

    if (!formData.email || !formData.password) {
      setError(['All fields are required']);
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await UserService.loginUser(formData);
      if (response.data.success) {
        const token = response.data.data.token;
        localStorage.setItem('token', token);
        login();
        setSuccess('Login successful! Redirecting...');
        setTimeout(() => { navigate('/'); if (onSuccess) onSuccess(); }, 2000);
        
      } else {
        setError(response.data.errors || ['An unexpected error occurred.']);
      }
    } catch (error: any) {
      setError(error.response?.data?.errors || ['An unexpected error occurred.']);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (field: keyof UserLogin, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseError = (index: number) => {
    setError(prev => prev.filter((_, i) => i !== index));
  };

  const handleCloseSuccess = (index: number) => {
    setSuccess('');
  };

  const formContent = (
    <Box component="form" onSubmit={handleSubmit}>
      <AlertMessage messages={error} severity="error" onClose={handleCloseError} />
      {success && <AlertMessage messages={[success]} severity="success" onClose={handleCloseSuccess} />}
      <TextField
        type="email"
        label="E-mail"
        value={formData.email}
        onChange={(e) => handleChange('email', e.target.value)}
        margin="normal"
        fullWidth
        required
        sx={{ mb: 1 }}
      />
      <TextField
        type={showPassword ? 'text' : 'password'}
        label="Password"
        value={formData.password}
        onChange={(e) => handleChange('password', e.target.value)}
        margin="normal"
        fullWidth
        required
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleClickShowPassword} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ),
        }}
        sx={{ mb: 1 }}
      />
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} mt={2}>
        <Button type="submit" variant="contained" color="primary" size="large" sx={{ width: { xs: '100%', sm: 'auto' } }} disabled={isSubmitting}>
          Login
        </Button>
        <Button component={Link} to="/register" size="large" sx={{ width: { xs: '100%', sm: 'auto' } }}>
          Register
        </Button>
      </Box>
    </Box>
  );

  return withoutCard ? (
    <>
      <Typography variant="h5" gutterBottom>
        Welcome back!
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Please login to your account.
      </Typography>
      {formContent}
    </>
  ) : (
    <BoxWrapper>
      <Typography variant="h5" gutterBottom>
        Welcome back!
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Please login to your account.
      </Typography>
      {formContent}
    </BoxWrapper>
  );
};

export default LoginPage;
