import React, { useEffect } from 'react';
import { Typography, Box, Link } from '@mui/material';
import BoxWrapper from '../../components/common/BoxWrapper';
import { Link as RouterLink } from 'react-router-dom';

const AboutPage: React.FC = () => {
  useEffect(() => {}, []);

  return (
    <>
      <BoxWrapper>
        <Typography variant="h5" gutterBottom>
          About Reuros Exchange
        </Typography>
        <Typography variant="body1">
          Reuros Exchange is a platform connecting people for currency exchange. It targets travelers, expatriates, and individuals interested in direct currency trading.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          Our Mission
        </Typography>
        <Typography variant="body1">
          Our aim is to offer a safe and reliable platform connecting people interested in currency exchange. Our community-orientated approach promotes trust and transparency.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          How It Works
        </Typography>
        <Typography variant="body1">
          Reuros Exchange operates like a social network for currency exchange:
        </Typography>
        <Box component="ul" sx={{ pl: 3 }}>
          <li><strong>Verified Users:</strong> Ensures a safe trading environment.</li>
          <li><strong>Global Community:</strong> Perfect for expatriates and travelers.</li>
          <li><strong>No Transaction Fees:</strong> Direct trades with no hidden costs.</li>
          <li><strong>Trust-Based:</strong> Ratings and reviews build confidence.</li>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          Who Is It For?
        </Typography>
        <Typography variant="body1">
          Reuros Exchange is perfect for:
        </Typography>
        <Box component="ul" sx={{ pl: 3 }}>
          <li><strong>Expatriates:</strong> Exchange money within your community abroad.</li>
          <li><strong>Travelers:</strong> Exchange currency while on the go.</li>
          <li><strong>P2P Traders:</strong> Enjoy secure, hassle-free trading.</li>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          User Ranking and Evaluation
        </Typography>
        <Typography variant="body1">
          Each transaction includes a rating and review system to ensure trust.
        </Typography>
        <Box component="ul" sx={{ pl: 3 }}>
          <li><strong>Ratings:</strong> Evaluate users after trades.</li>
          <li><strong>Reviews:</strong> Provide detailed feedback.</li>
          <li><strong>Reputation:</strong> Build trust with visible scores.</li>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          Reporting Fraud
        </Typography>
        <Typography variant="body1">
          Fraud is taken seriously. Users can report suspicious activity, and the community can ban fraudulent users.
        </Typography>
        <Box component="ul" sx={{ pl: 3 }}>
          <li><strong>Report Suspicious Activity:</strong> Flag suspicious users or trades.</li>
          <li><strong>Community Banning:</strong> Users can vote to ban violators.</li>
          <li><strong>Transparency:</strong> All reports are carefully reviewed.</li>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          Join Us
        </Typography>
        <Typography variant="body1">
          Join Reuros Exchange for secure, seamless currency trading. Be part of a global community and take control of your financial exchanges today.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          Learn More
        </Typography>
        <Typography variant="body1">
          For more information, please read our <Link component={RouterLink} to="/terms">Terms of Use</Link> and <Link component={RouterLink} to="/privacy">Privacy Policy</Link>.
        </Typography>
      </BoxWrapper>
    </>
  );
}

export default AboutPage;
