import React, { ReactNode } from 'react';
import { Box, Container } from '@mui/material';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Breadcrumb from './Breadcrumb';

interface DesktopProps {
  isLoggedIn: boolean;
  handleLogout: () => void;
  children: ReactNode;
}

function Desktop({ isLoggedIn, handleLogout, children }: DesktopProps) {
  const pathnames = window.location.pathname.split('/').filter((x) => x);

  return (
    <>
      <Header isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
      <Container component="main" sx={{ flexGrow: 1, py: 4 }}>
        <Breadcrumb pathnames={pathnames} />
        <Box sx={{ mt: 2 }}>
          {children}
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default Desktop;