import React, { ReactNode } from 'react';
import { Box, Container } from '@mui/material';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import BottomNav from './BottomNav';

interface MobileProps {
  isLoggedIn: boolean;
  handleLogout: () => void;
  children: ReactNode;
}

const Mobile: React.FC<MobileProps> = ({ isLoggedIn, handleLogout, children }) => {
  return (
    <>
      <Header isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
      <Container component="main" sx={{ flexGrow: 1, py: 4 }}>
        <Box sx={{ mt: 2 }}>
          {children}
        </Box>
      </Container>
      <Footer />
      <BottomNav isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
    </>
  );
};

export default Mobile;