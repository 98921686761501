import React, { ReactNode } from 'react';
import { Box, Paper, PaperProps } from '@mui/material';

interface BoxWrapperProps extends PaperProps {
  children: ReactNode;
}

const BoxWrapper: React.FC<BoxWrapperProps> = ({ children, ...props }) => {
  return (
    <Paper elevation={1} variant="elevation" sx={{ p: 2, mt: 1 }} {...props}>
      <Box>
        {children}
      </Box>
    </Paper>
  );
};

export default BoxWrapper;